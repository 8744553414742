import React from 'react'
import './bodydata.css'


function Bodyreport() {
    return (
        <div className='bodyreport'>
            <div className='reportlogo'>Device Verification Report</div>
           

          
            
        </div>
    )
}

export default Bodyreport
