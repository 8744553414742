import React from 'react'
import './gradient_line.css'

function Gradientline() {
    return (
        <div className='gradient_line'>
        

          
            
        </div>
    )
}

export default Gradientline
