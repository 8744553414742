import React from 'react'
import './line.css'

export default function Line() {
    return (
        <div className='line'>
            
        </div>
    )
}

