import React from 'react'
import './footer_logo.css'

function Footerlogo() {
    return (
        <div className='footer_logo'>
            <img src='https://firebasestorage.googleapis.com/v0/b/phonescore-f0e9e.appspot.com/o/phonescore-logo-transparent.png?alt=media&token=778389c1-a402-46ea-a86c-d775d575a460' alt=''></img>
            
        </div>
    )
}

export default Footerlogo
