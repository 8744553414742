import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAzCTA7oagMd9JZKNZL4QzrdaA3AYYm0WA",
    authDomain: "phonescore-f0e9e.firebaseapp.com",
    databaseURL: "https://phonescore-f0e9e.firebaseio.com",
    projectId: "phonescore-f0e9e",
    storageBucket: "phonescore-f0e9e.appspot.com",
    messagingSenderId: "238506023308",
    appId: "1:238506023308:web:ad2ff27802c1d6396b5ea9",
    measurementId: "G-2C59EGR0GV"
  };

  firebase.initializeApp(firebaseConfig);
  export default firebase;
