import './LoginUi.css';
import profile from "./../image/a.png";
import email from "./../image/phone.png";
function LoginUi() {
  return (
    <div className="main">
     <div className="sub-main">
       <div>
         <div className="imgs">
           <div className="container-image">
             <img src={profile} alt="profile" className="profile"/>

           </div>


         </div>
         <div>
           <h1>Login Page</h1>
           <div>
             <img src={email} alt="email" className="email"/>
             <input type="text" placeholder="Phone No" className="name"/>
           </div>
          
          <div className="login-button">
          <button>Login</button>
          </div>
           
           
           
 
         </div>
       </div>
       

     </div>
    </div>
  );
}

export default LoginUi;
