import React from 'react'
import Testcart from '../testcart'
import './body.css'
import Footerlogo from './footer_logo/footer_logo'
import KYC from './KYC/kyc'
import Line from './line/line'



function Body() {
    return (
        <div className='body'>
            <Testcart></Testcart>
            <Line></Line>
            <KYC></KYC>
            <Footerlogo></Footerlogo>
           
           

          
            
        </div>
    )
}

export default Body
