import React from "react";

function Payment(){

    const loadScript = (src)=>{
        return new Promise((resolve)=>{
          const script = document.createElement('script')
          script.src = src
          script.onload=()=>{
            resolve(true)
          }
          script.onerror=()=>{
            resolve(false)
          }
          document.body.appendChild(script)
    
          
        })
    
    
      }
      const dispalyRazorpay = async(amount) =>{
        console.log(amount)
       const res =await loadScript('https://checkout.razorpay.com/v1/checkout.js')
       if(!res){
         alert('You are offline... Failed to load Razorpay')
         return
       }
    
       var options = {
        key: "rzp_test_amx1VyubRPCMo2", // Enter the Key ID generated from the Dashboard
        amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Phonescore",
        description: "Test Transaction",
        //"image": "https://example.com/your_logo",
        //"order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response){
            alert(response.razorpay_payment_id);
            alert(response.razorpay_order_id);
            alert(response.razorpay_signature)
        },
        prefill: {
            "name": "Kayyum Subhedar",
            "email": "kayyumsubhedar1@gmail.com",
            "contact": "8788500287"
        },
        notes: {
            "address": "Razorpay Corporate Office"
        },
        theme: {
            "color": "#3399cc"
        }
        };
    
          const  raz1 = new window.Razorpay(options)
          raz1.open()
    
    
      }

    return(  <div className="App">
     
     <div className = "payNow">
        <h2>Pay Now</h2>
       
    
      <button onClick={()=>dispalyRazorpay(100)}>PAY NOW</button>
     


      </div>
    </div>
        




    );


}
export default Payment;