import React from "react";

export default function Privacy(){

    return  <div>

<img alt="" src = {require('./images/phonescore.png')}height={150}width={200} style={{ alignSelf: 'center' }} className="center"></img>
        <h1>Privacy Policy - PhoneScore</h1>
     


Your privacy is very important to "PhoneScore". This Privacy Policy covers how we collect, use, disclose, transfer, and store your information. Please take a moment to get to know our practices and contact us if you have any questions.
<h2>
Collection and Use of Personal Information</h2>
Personal information is data that can be used to uniquely identify or contact a specific individual. We use the personal information we collect to help us develop, deliver, protect, and improve our products, services, content, and customer communications. We also use it to keep you up to date on our latest product announcements and updates, and to send you important notices, e.g. about changes to our Terms of Service or our Privacy Policy. You may opt out of receiving some of these communications. We may also use personal information for internal purposes such as auditing, data analysis, and research to improve our products and services.

<h2>Collection and Use of Non-Personal Information</h2>
Non-personal information is data that cannot be used to uniquely identify a specific individual. We may collect, use, transfer, and disclose non-personal information for any purpose. We may combine personal and non-personal information for certain purposes; this data will then be treated as personal information for as long as it remains combined. Conversely, aggregated information, i.e. data that might have originated from personal information, but that has been processed in such a way as to not allow personal identification, is treated as non-personal information. Here are some examples of non-personal information that we collect – either by ourselves or through the use of third-party services:
• When you visit our website, we keep logs of your Internet Protocol (IP) address, browser type and language, referring and exit pages, operating system and date/time stamp.
• When our App/Software crashes, we collect and store crash reports to help us analyze the crash and determine the source of the problem.
• Our App/Software periodically checks our servers for important updates or notices; in this case, we also log the IP address, device type, language, operating system and the date and time of the request.

<h2>Access to Personal Information</h2>
When using our services or app, we aim to provide you with the means to view, edit, and remove your personal information. We make good faith efforts to provide you with access to your data so that you can request its correction or deletion. We will promptly process these requests, unless we are required to retain information by law or for legitimate business purposes. We may also decline requests if they would be extremely impractical, are unreasonably repetitive, require disproportionate technical effort, risk the privacy of others, or for which access is not required by law. Your personal information is retained for as long as it is necessary in order to provide you our services, to fulfill the purposes outlined in this Privacy Policy, to enforce applicable Terms of Service, or to comply with our legal obligations. After we delete or block access to information from our services or app, it will no longer be accessible to anyone; there may, however, continue to be residual copies of that data on our active servers and backup systems.
<h2>
Disclosure to 3rd parties</h2>
We don’t share personal information with anyone outside of PhoneScore, except for the few exceptions below. We work together with other companies who provide services to us, like hosting our website & app, providing our help desk software, sending out newsletters, analyzing our website & app traffic, hosting our cloud services, etc.. We only share personal information with these companies if it is necessary for them to provide their services. All companies we work with provide at least industry standard systems and processes to ensure the security and confidentiality of your information. We also share personal information if disclosure of such information is reasonably necessary to satisfy any applicable law, regulation, legal process or enforceable governmental request; to enforce applicable Terms of Service, including investigation of potential violations thereof; to detect, prevent, or otherwise address fraud or security issues; and to protect against harm to the rights, property or safety of "PhoneScore”, its users or the public as required or permitted by law. If PhoneScore is involved in a reorganization, merger, or sale, the information we collect may be transferred as part of that transaction.

<h2>Protection of Personal Information</h2>
We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data. Whenever you connect to our service, we use Secure Sockets Layer (SSL) encryption for all information that is being transmitted. However, no method of transmitting or storing data is 100% secure, so we cannot guarantee the security of information you transmit to us. Inside PhoneScore, we restrict access to personal information to only those employees who need to know that information in order to deploy and maintain our services. These individuals are bound by confidentiality agreements and may be subject to discipline, including termination and criminal prosecution, if they fail to meet these obligations. 

Some parts of our website, such as our certificate page, may showcase personal information, such as your name and/or semi-hidden contact number to potential buyers. This information shall especially be accessible to buyers to purchase premium services offered by PhoneScore. 

Please note that some of our servers may be located outside of the European Union. If you use our services or website, you agree that your information is transmitted, stored, and processed in data centers outside the EU.

<h2>Children</h2>
We do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will promptly delete this information. Parents or guardians can contact us at: info@connectmobiles.com 

<h2>3rd Party Sites and Services</h2>
PhoneScore website or any of our services may contain links to third-party websites or services. We are not responsible for the information collected by those third parties and we encourage you to read their privacy policy before submitting any personal information to them.

<h2>About this Privacy Policy</h2>
Our Privacy Policy may change from time to time. When we change the policy, we will post the changes on this page, and, if the policy changes in a significant way, we will also provide a prominent notice (e.g. on our website).If you have any questions regarding our Privacy Policy, please contact us at: info@connectmobiles.com 


 </div>;
    
       
    
}
