import './App.css';
import React from 'react';
import Privacy from './privacy-policy';
import Payment from './payment';
import LoginUi from './Login Ui/LoginUi';
import {
  BrowserRouter as Router,Routes,Route
}from "react-router-dom";
import Home from './Home';
import Certificate from './components/certificate';

function App() {
  return (
   <Router>
  
     <Routes>
       <Route path="/" element={<Home />} />
       <Route path="/payment" element={<Payment />} />
       <Route path="/privacy-policy" element={<Privacy />} />
       <Route path="/login" element={<LoginUi />} />
       <Route path="/certificate" element={<Certificate />} />

       
         
     
     </Routes>
   </Router>
  );
}

export default App;
