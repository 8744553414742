import React from 'react';
import Body from './body/body';
import Bodycart from './body/bodydata';
import Deviceinfo from './device_info';
import Gradientline from './gradient_line/gradient_line';
import Header from './Header';
function Certificate(){

    
   
    
    return(

        <div className='certificate'>
            <Header></Header>
            <Deviceinfo/>
           <Gradientline/>
           <Bodycart/>
           <Body/>
           
           

            
        </div>
    );
}
export default Certificate;