import React from 'react'
import headerlogo from "./../images/logo.png";
import {useState} from 'react';
import QRcode from 'qrcode.react'
import './Header.css'
import firebase from '../Firebase';
const currentDate = new Date().toLocaleDateString();



function Header() {
   var finalScore = 0;
    const db = firebase.firestore();
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    var url ='https://www.connectmobiles.com/certificate/?id=' + id;
    
///////////////////////////////////////////////////////////TestWeights////////////////////////////////////////////////////////////////////////////


const [ multiTouchTestWeight, multiTouchTest] = useState({});
db.collection("TestsWeight").doc("MultiTouch").get().then((snapshot)=>{
    if(snapshot){
        multiTouchTest(snapshot.data());

    }
});


const [ accelerometerTestWeight, accelerometerTest] = useState({});
db.collection("TestsWeight").doc("Accelerometer").get().then((snapshot)=>{
    if(snapshot){
       accelerometerTest(snapshot.data());

    }
});
const [ gyroscopeTestWeight, gyroscopeTest] = useState({});
db.collection("TestsWeight").doc("Gyroscope").get().then((snapshot)=>{
    if(snapshot){
       gyroscopeTest(snapshot.data());

    }
});
const [ proximityTestWeight, proximityTest] = useState({});
db.collection("TestsWeight").doc("Proximity").get().then((snapshot)=>{
    if(snapshot){
        proximityTest(snapshot.data());

    }
});
const [ earpieceTestWeight,earpieceTest] = useState({});
db.collection("TestsWeight").doc("EarPhones").get().then((snapshot)=>{
    if(snapshot){
       earpieceTest(snapshot.data());

    }
});
const [ speakerTestWeight, speakerTest] = useState({});
db.collection("TestsWeight").doc("Speakers").get().then((snapshot)=>{
    if(snapshot){
       speakerTest(snapshot.data());

    }
});

const [ vibrationTestWeight, vibrationTest] = useState({});
db.collection("TestsWeight").doc("Vibration").get().then((snapshot)=>{
    if(snapshot){
       vibrationTest(snapshot.data());

    }
});

  


///////////////////////////////////////////////////////////Score////////////////////////////////////////////////////////////////////////////

const [ multiTouchResult, multiTouch] = useState({});
     db.collection(id).doc("multitouch").get().then((snapshot)=>{
         if(snapshot){
             multiTouch(snapshot.data());
 
         }
     });

     const [ accelerometerResult,accelerometer] = useState({});
     db.collection(id).doc("accelerometer").get().then((snapshot)=>{
         if(snapshot){
             accelerometer(snapshot.data());
 
         }
     });
     const [gyroscopeResult,gyroscope] = useState({});
     db.collection(id).doc("gyroscope").get().then((snapshot)=>{
         if(snapshot){
             gyroscope(snapshot.data());
     
         }
     });
     const [proximityResult,proximity] = useState({});
     db.collection(id).doc("proximity").get().then((snapshot)=>{
         if(snapshot){
             proximity(snapshot.data());
     
         }
     });

     const [vibrationResult,vibration] = useState({});
        db.collection(id).doc("vibration").get().then((snapshot)=>{
            if(snapshot){
                vibration(snapshot.data());

            }
        });

        const [earpieceResult,earpiece] = useState({});
        db.collection(id).doc("earpiece").get().then((snapshot)=>{
            if(snapshot){
                earpiece(snapshot.data());

            }
        });

        const [speakerResult,speaker] = useState({});
        db.collection(id).doc("speaker").get().then((snapshot)=>{
            if(snapshot){
                speaker(snapshot.data());

            }
        });
      

        if( multiTouchResult.testResult === 1){

            finalScore +=  parseInt(multiTouchTestWeight.default) +parseInt(multiTouchTestWeight.relevence);
        }
        if( accelerometerResult.testResult === 1){

            finalScore +=  parseInt(accelerometerTestWeight.default) +parseInt(accelerometerTestWeight.relevence);
        }
        if( gyroscopeResult.testResult === 1){

            finalScore +=  parseInt(gyroscopeTestWeight.default) +parseInt(gyroscopeTestWeight.relevence);
        }
        if( proximityResult.testResult === 1){

            finalScore +=  parseInt(proximityTestWeight.default) +parseInt(proximityTestWeight.relevence);
        }
        if( vibrationResult.testResult === 1){

            finalScore +=  parseInt( vibrationTestWeight.default) +parseInt( vibrationTestWeight.relevence);
        }
        if( earpieceResult.testResult === 1){

            finalScore +=  parseInt( earpieceTestWeight.default) +parseInt( earpieceTestWeight.relevence);
        }
        if( speakerResult.testResult === 1){

            finalScore +=  parseInt( speakerTestWeight.default) +parseInt( speakerTestWeight.relevence);
        }
    

   
    return (
        <div className='header'>
            <div className='header_left'>
                <div><img className='header_logo' src={headerlogo} alt=''></img></div>
                <div>  <img className='header_logotext' src='https://firebasestorage.googleapis.com/v0/b/phonescore-f0e9e.appspot.com/o/phonescore-logo.png?alt=media&token=eae8fcb2-278b-4437-ac5f-8524bef3bfb4' alt=''></img>
                <div>  <img className='logo_report' src='https://firebasestorage.googleapis.com/v0/b/phonescore-f0e9e.appspot.com/o/logo_report.PNG?alt=media&token=d3c4fffb-4162-401f-acb0-c1376d236207' alt=''></img>
               </div>
               </div>                

            </div>
            <div className='header_right'>
            

                <div className='qr_code'>
                <QRcode    value={url} ></QRcode>
                   
                    
                </div>
                <div className='user_id'>
                    <div className='score'>{finalScore.toFixed(2)}</div>
                    <div className='userid'>User ID: 869 544</div>
                    <div className='reportid'>Report ID: PSR 4842 </div>
                    <div className='date'>Date: {currentDate.replaceAll('/','-')}</div>
                    
                </div>

                
            </div>
          
        
          
            
        </div>
    )
}

export default Header
