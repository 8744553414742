import React from 'react'
import './kyc.css'

function KYC() {
    return (
        <div className='kyc'>
        <div className='kyc_carts'>
            <div className='anti_theft'>
            <img className='anti_theft_logo' src='https://firebasestorage.googleapis.com/v0/b/phonescore-f0e9e.appspot.com/o/anti-theft-icn.png?alt=media&token=60798c66-1a8f-40c1-9e89-871f80f58043' alt=''></img>
            <div className='anti_theft_verification'>Anti Theft Verification</div>
           <img className='anti_theft_check' src='https://firebasestorage.googleapis.com/v0/b/phonescore-f0e9e.appspot.com/o/tick.png?alt=media&token=e1c1adee-7294-4a80-b15e-d8a9efa3566c' alt=''></img>
            <div className='anti_theft_test'>
           <div className='anti_theft_test_taken'>7 Days Ago</div>

           </div>
            </div>
            <div className='contact'>
            <img className='contact_logo' src='https://firebasestorage.googleapis.com/v0/b/phonescore-f0e9e.appspot.com/o/contact-ver-icon.png?alt=media&token=092edefb-2916-45c8-a075-f0a8b75e7b7e' alt=''></img>
            <div className='contact_verification'>Contact Verification</div>
            <img className='contact_check' src='https://firebasestorage.googleapis.com/v0/b/phonescore-f0e9e.appspot.com/o/tick.png?alt=media&token=e1c1adee-7294-4a80-b15e-d8a9efa3566c' alt=''></img>
            <div className='contact_test'><div className='contact_test_taken'>7 Days Ago</div>
             </div>
            
           </div>
           </div>
            
            
        </div>
       
    )
}

export default KYC
