
import './device_info.css'
import firebase from '../Firebase';
import React,{useState} from 'react';
import { doc } from 'firebase/firestore';
function Deviceinfo(){
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');

    const ref = firebase.firestore().collection(id).doc('deviceInfo');
    const [singleDoc,setSingleDoc] = useState({});
    const db = firebase.firestore();
    db.collection(id).doc("deviceInfo").get().then((snapshot)=>{
        if(snapshot){
            setSingleDoc(snapshot.data());

        }
    });
     
    

    
  
    return (
        <div className='device_info'>
            <div className='info'>Device info</div>
            <div className='brand'>Brand : {singleDoc.brand} Model : {singleDoc.model}</div>
            <div className='os'>OS Version : Android {singleDoc.osVersion} </div>
        

          
            
        </div>
    )
}

export default Deviceinfo
