import React from 'react'
import './testcart.css'
import screenLogo from "./../images/cert-scrn.png";
import sensorLogo from "./../images/cert-sensor.png";
import outputLogo from "./../images/cert-output.png";
import tick from "./../images/tick.png";
import notick from "./../images/no-tick.png";
import notest from "./../images/no-test.png";
import firebase from '../Firebase';
import {useState} from 'react';
const currentDate = new Date().toLocaleDateString();



function Testcart() {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    var multitouchTick = notest;
    var accelerometerTick = notest;
    var gyroscopeTick = notest;
    var proximityTick = notest;
    var vibratorTick = notest;
    var earpieceTick = notest;
    var speakerTick = notest;
   

    //currentDate = currentDate.replaceAll('/','/')
     
     var todayDate = new Date(`${currentDate.toString()}`);
     const db = firebase.firestore();
       
   
////////////////////////////////////////////////////////-MULTITOUCH-////////////////////////////////////////////////////////////////////////////////////
     const [ multiTouchResult, multiTouch] = useState({});
     db.collection(id).doc("multitouch").get().then((snapshot)=>{
         if(snapshot){
             multiTouch(snapshot.data());
 
         }
     });
     
     var moment= require('moment');
     var now = moment(new Date()); //todays date
     var multiTouchResultend = moment(multiTouchResult.time); // another date
     var multiTouchResultduration = moment.duration(now.diff(multiTouchResultend));
     var multiTouchResultdays = multiTouchResultduration.asDays();

     if(multiTouchResult.testResult===1){
        multitouchTick = tick;

     }else if(multiTouchResult.testResult===0){

        multitouchTick = notick;

     }
///////////////////////////////////////////////////////////-Accelerometer-/////////////////////////////////////////////////////////////////////////////////
       const [ accelerometerResult,accelerometer] = useState({});
       db.collection(id).doc("accelerometer").get().then((snapshot)=>{
           if(snapshot){
               accelerometer(snapshot.data());
   
           }
       });
        // var accelerometerDate = new Date("2020/1002 20:22:30.158169");
         // var Difference_accelerometer_Time = todayDate.getTime() - accelerometerDate.getTime();
        // var Difference_accelerometer_Days = (Difference_accelerometer_Time / (1000 * 3600 * 24)).toFixed(0);
        var accelerometerDatefirebase = `${accelerometerResult.time}`;
        let splitData = accelerometerDatefirebase.split(" ");
        const s = splitData[0].toString()
        var da=s.replaceAll('-','/');
        let [y, m, d] = da.split(/\D/);
         
            // Use the Date constructor
        let date = new Date(y, m-1, d);
      //  var newdate = new Date(`${d}`);
     
      

            var now = moment(new Date()); //todays date
            var end = moment(accelerometerResult.time); // another date
            var duration = moment.duration(now.diff(end));
            var days = duration.asDays();
            if(accelerometerResult.testResult===1){
                accelerometerTick = tick;
        
             }else if(accelerometerResult.testResult===0){
        
                accelerometerTick = notick;
        
             }
                      

////////////////////////////////////////////////////////////////-Gyroscope-////////////////////////////////////////////////////////////////////////////
const [gyroscopeResult,gyroscope] = useState({});
db.collection(id).doc("gyroscope").get().then((snapshot)=>{
    if(snapshot){
        gyroscope(snapshot.data());

    }
});
var gyroscopeResultend = moment(gyroscopeResult.time); // another date
var gyroscopeResultduration = moment.duration(now.diff(gyroscopeResultend));
var gyroscopeResultdays =gyroscopeResultduration.asDays();

if(gyroscopeResult.testResult===1){
    gyroscopeTick = tick;

 }else if(gyroscopeResult.testResult===0){

    gyroscopeTick = notick;

 }

////////////////////////////////////////////////////////////////-Proximity-////////////////////////////////////////////////////////////////////////////
const [proximityResult,proximity] = useState({});
db.collection(id).doc("proximity").get().then((snapshot)=>{
    if(snapshot){
        proximity(snapshot.data());

    }
});
var proximityResultend = moment(proximityResult.time); // another date
var proximityResultduration = moment.duration(now.diff(proximityResultend));
var proximityResultdays =proximityResultduration.asDays();


if(proximityResult.testResult===1){
    proximityTick = tick;

 }else if(proximityResult.testResult===0){

    proximityTick = notick;

 }
  
////////////////////////////////////////////////////////////////-Vibration-////////////////////////////////////////////////////////////////////////////
const [vibrationResult,vibration] = useState({});
db.collection(id).doc("vibration").get().then((snapshot)=>{
    if(snapshot){
        vibration(snapshot.data());

    }
});
var vibrationResultend = moment(vibrationResult.time); // another date
var vibrationResultduration = moment.duration(now.diff(vibrationResultend));
var vibrationResultdays =vibrationResultduration.asDays();

if(vibrationResult.testResult===1){
    vibratorTick = tick;

 }else if(vibrationResult.testResult===0){

    vibratorTick = notick;

 }
  
////////////////////////////////////////////////////////////////-Earpiece-////////////////////////////////////////////////////////////////////////////
const [earpieceResult,earpiece] = useState({});
db.collection(id).doc("earpiece").get().then((snapshot)=>{
    if(snapshot){
        earpiece(snapshot.data());

    }
});
var earpieceResultend = moment(earpieceResult.time); // another date
var earpieceResultduration = moment.duration(now.diff(earpieceResultend));
var earpieceResultdays =earpieceResultduration.asDays();

if(earpieceResult.testResult===1){
    earpieceTick = tick;

 }else if(earpieceResult.testResult===0){

    earpieceTick = notick;

 }
      
////////////////////////////////////////////////////////////////-Speaker-////////////////////////////////////////////////////////////////////////////
const [speakerResult,speaker] = useState({});
db.collection(id).doc("speaker").get().then((snapshot)=>{
    if(snapshot){
        speaker(snapshot.data());

    }
});
var speakerResultend = moment(speakerResult.time); // another date
var speakerResultduration = moment.duration(now.diff(speakerResultend));
var speakerResultdays =speakerResultduration.asDays();
   
if(speakerResult.testResult===1){
    speakerTick = tick;

 }else if(speakerResult.testResult===0){

    speakerTick = notick;

 }   
    return (<div className='carts'>
        <div className='Screen'>
                <div className='Screen_heading'>
                    <img src={screenLogo} alt=''></img>
                    <div className='Screen_id'>Screen</div>
                     
                    
                     </div>
                 <div className='Screen_tests'> 
                    <div className='Screen_touch'>
                    <div className='Screen_test_name'> Touch</div>
                    <div><img className='Screen_test_check'src={tick} alt=''></img></div>
                    
                    <div className='Screen_ago'>Coming soon</div>
                    <div className='Screen_line'> </div>
                         </div>
                    <div className='Screen_multitouch'>
                    <div className='Screen_test_name'> Multi-Touch</div>
                    <div><img className='Screen_test_check'src={multitouchTick} alt=''></img></div>
                    
                    <div className='Screen_ago'> {multiTouchResultdays.toFixed(0)} Days Ago  </div>
                    <div className='Screen_line'> </div>
                    
                        
                         </div>
                    <div className='Screen_pixes'>
                    <div className='Screen_test_name'> Pixels</div>
                    <div><img className='Screen_test_check'src={tick} alt=''></img></div>
                    
                    <div className='Screen_ago'>Coming soon </div>
                    
                         
                         </div>
                 
                     

                     </div>

           
        </div>



        <div className='Sensors'>
                <div className='Sensors_heading'>
                <img src={sensorLogo} alt=''></img>
                <div className='Sensors_id'>Sensors</div>
                     
                   
                    
                    </div>
                <div className='Sensors_tests'> 

                <div className='Screen_touch'>
                    <div className='Screen_test_name'> Accelerometer</div>
                    <div><img className='Screen_test_check'src={accelerometerTick} alt=''></img></div>
                    
                    <div className='Sensors_ago'>{days.toFixed(0)} Days Ago  </div>
                    <div className='Sensors_line'> </div>
                         </div>
                    <div className='Screen_multitouch'>
                    <div className='Screen_test_name'> Gyroscope</div>
                    <div><img className='Screen_test_check'src={gyroscopeTick} alt=''></img></div>
                    
                    <div className='Sensors_ago'>{gyroscopeResultdays.toFixed(0)} Days Ago </div>
                    <div className='Sensors_line'> </div>
                    
                        
                         </div>
                    <div className='Screen_pixes'>
                    <div className='Screen_test_name'> Proximity</div>
                    <div><img className='Screen_test_check'src={proximityTick} alt=''></img></div>
                    
                    <div className='Sensors_ago'>{proximityResultdays.toFixed(0)} Days Ago </div>
                    
                         
                         </div>
                    </div>
        </div>

        <div className='Output'>
        <div className='Output_heading'>
                 <img src={outputLogo} alt=''></img>
                     <div className='Output_id'>Output</div>
                     
                    </div>
                <div className='Output_tests'>
                <div className='Screen_touch'>
                    <div className='Screen_test_name'> Vibrator</div>
                    <div><img className='Screen_test_check'src={vibratorTick} alt=''></img></div>
                    
                    <div className='Output_ago'>{vibrationResultdays.toFixed(0)} Days Ago </div>
                    <div className='Output_line'> </div>
                         </div>
                    <div className='Screen_multitouch'>
                    <div className='Screen_test_name'> Earpiece</div>
                    <div><img className='Screen_test_check'src={earpieceTick} alt=''></img></div>
                    
                    <div className='Output_ago'>{earpieceResultdays.toFixed(0)} Days Ago </div>
                    <div className='Output_line'> </div>
                    
                        
                         </div>
                    <div className='Screen_pixes'>
                    <div className='Screen_test_name'> Speakers</div>
                    <div><img className='Screen_test_check'src={speakerTick} alt=''></img></div>
                    
                    <div className='Output_ago'>{speakerResultdays.toFixed(0)} Days Ago </div>
                    
                         
                         </div> 
                    </div>
           
        </div>
        </div>
        
    )
}

export default Testcart
