import React from "react";

function Home(){

    return <div>
        This is home page
    </div>;


}
export default Home;